.pedals {
    position: absolute;
    filter: drop-shadow(rgb(0, 0, 0, 0.5) 5px 5px 7px);
    outline: none;
}

.pedalboards {
    position: absolute;
    filter: drop-shadow(rgb(0, 0, 0, 0.5) 5px 5px 7px);
    outline: none;
}

.powersupplies {
    position: absolute;
    filter: drop-shadow(rgb(0, 0, 0, 0.5) 5px 5px 7px);
    outline: none;
}

.pedals::after, .pedalboards::after, .powersupplies::after{
    content: " ";
    border-radius: 8px;
    border: 2px solid transparent;
    left: -6px;
    right: -6px;
    bottom: -6px;
    top: -6px;
    position: absolute;
    transition: 0.25s;
}

.pedals:hover::after, .pedalboards:hover::after, .powersupplies:hover::after{
    border-color: rgb(63, 133, 235, 1);
}

.pedals.selected::after, .pedalboards.selected::after, .powersupplies.selected::after{
    border-color: rgb(63, 133, 235, 1);
}

.options {
    z-index: 1;
    position: absolute;
    background: #111111;
    border: 1px solid lightgray;
    border-radius: 20px;
    width: 75px;
    height: 30px;
    top: -20px;
    right: -30px;
    opacity: 0;
    transition: 0.25s;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pedals:hover .options, .pedalboards:hover .options, .powersupplies:hover .options {
    opacity: 1;
}

.rotatable {
    transition: 0.3s;
}